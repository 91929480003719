import {createStore, applyMiddleware, compose} from 'redux';
import rootReducer, {SaveSubsetFilter} from './rootReducer';
import thunk from 'redux-thunk';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

let composer;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
composer = composeEnhancers;

// Persist Config
const config = {
    key: 'root',
    whitelist: ['core'],
    storage,
    debug: false,
    transforms: SaveSubsetFilter,
};

// Init redux store (using the given reducer & middleware)
const enhancer = composer(applyMiddleware(thunk));

const reducer = persistReducer(config, rootReducer);
const appStore = createStore(reducer, enhancer);

export {appStore};
