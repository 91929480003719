
import { Router, createHistory, LocationProvider } from "@reach/router";
import ReactGA from 'react-ga';

import Home from '../containers/Home';
import RiskManagement from '../containers/RiskManagement';

const TRACKING_ID = "G-17DMDT0ESV";
ReactGA.initialize(TRACKING_ID);
const history= createHistory(window);

function Navigation() {

    history.listen( window => {
        ReactGA.pageview(window.location.pathname+ window.location.search);
        console.log('page=>',window.location.pathname);
    });

    return (
        <LocationProvider history={history}>
            <Router>
                <Home path="/" />
                <RiskManagement path="/risk-management" />
            </Router>
        </LocationProvider>
    );
}

export default Navigation;