import { css } from '@emotion/react'

const styles = {
    
    background: css`
        background: rgb(250,130,134);
        background: linear-gradient(11deg, rgba(250,130,134,1) 0%, rgba(252,203,255,1) 35%, rgba(255,217,217,1) 73%, rgba(0,212,255,1) 100%);
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1
    `,
    
    main: css`

        & a {
            color: #393939
        }
    `
      
}

export {styles};