/** @jsxImportSource @emotion/react */

import {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';

import {styles} from './styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexWrap: 'wrap',
    },
    margin: {
      margin: '15px 0',
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '100%',
    },
    btn: {
        margin: '10px 0'
    }
  }));

function RiskManagement() {
    const classes = useStyles();
    const [values, setValues] = useState({
        balance: '',
        risk: '',
        price: '',
        stopLoss: '',
    });
    const [validation, setValidation] = useState({
        balance: false,
        risk: false,
        price: false,
        stopLoss: false,
    });
    const [units, setUnits] = useState(0);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        setValidation({
            balance: false,
            risk: false,
            price: false,
            stopLoss: false,
        })
    };

    const _submit = () => {
        const balanceNum = +values.balance;
        const riskNum = +values.risk;
        const priceNum = +values.price;
        const stopLossNum = +values.stopLoss;

        if (!values.balance || balanceNum < 1) {
            setValidation({ ...validation, balance: true });
            return
        }
        else {
            setValidation({ ...validation, balance: false });
        }

        if (!values.risk || riskNum < 0 || riskNum > 100) {
            setValidation({ ...validation, risk: true });
            return
        }
        else {
            setValidation({ ...validation, risk: false });
        }

        if (!values.price || priceNum < 1) {
            setValidation({ ...validation, price: true });
            return
        }
        else {
            setValidation({ ...validation, price: false });
        }

        if (!values.stopLoss || stopLossNum < 1 || stopLossNum >= priceNum) {
            setValidation({ ...validation, stopLoss: true });
            return
        }
        else {
            setValidation({ ...validation, stopLoss: false });
        }

        const a1 = balanceNum * (riskNum / 100);
        const a2 = priceNum - stopLossNum;
        const result = a1 / a2;
        setUnits(parseFloat(result.toFixed(5)));
    }

    return (
        <Container>
            {/* <Box textAlign='center'> */}
            <div css={styles.calText}>
                <h1>
                    Risk Management Calculator
                </h1>
            </div>
            {/* </Box> */}
            <div css={styles.form}>
                <Grid container justify="center">
                <form className={classes.root} noValidate autoComplete="off">
                    <div>

                        <FormControl fullWidth className={classes.margin} variant="outlined">
                            <InputLabel error={validation.balance} htmlFor="account-balance">Account Balance</InputLabel>
                            <OutlinedInput
                                id="account-balance"
                                error={validation.balance}
                                value={values.balance}
                                type="number"
                                onChange={handleChange('balance')}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                labelWidth={125}
                            />
                        </FormControl>

                        <FormControl fullWidth className={classes.margin} variant="outlined">
                            <InputLabel error={validation.risk} htmlFor="risk-level">Risk Level</InputLabel>
                            <OutlinedInput
                                id="risk-level"
                                error={validation.risk}
                                value={values.risk}
                                type="number"
                                onChange={handleChange('risk')}
                                startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                labelWidth={80}
                            />
                        </FormControl>

                        <FormControl fullWidth className={classes.margin} variant="outlined">
                            <InputLabel error={validation.price} htmlFor="entry-price">Entry Price</InputLabel>
                            <OutlinedInput
                                id="entry-price"
                                error={validation.price}
                                value={values.price}
                                type="number"
                                onChange={handleChange('price')}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                labelWidth={85}
                            />
                        </FormControl>

                        <FormControl fullWidth className={classes.margin} variant="outlined">
                            <InputLabel error={validation.stopLoss} htmlFor="stop-loss">Stop Loss</InputLabel>
                            <OutlinedInput
                                id="stop-loss"
                                error={validation.stopLoss}
                                value={values.stopLoss}
                                type="number"
                                onChange={handleChange('stopLoss')}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                labelWidth={80}
                            />
                        </FormControl>
                    </div>
                    <Box textAlign='center'>
                        <Button
                            className={classes.btn}
                            variant="outlined"
                            color="primary"
                            onClick={() => _submit()}
                        >
                            Calculate
                        </Button>
                    </Box>
                    {
                        units > 0 ?
                            <Box textAlign='center'>
                                <p style={{ color: '#424242', fontSize: '15px' }}>
                                    Number of the asset units that you must buy:
                                </p>
                                <p style={{ color: '#01579B', fontSize: '18px', fontWeight: 'bold' }}>
                                    {units} Units
                                </p>
                                <p style={{ color: '#004D40', fontSize: '13px' }}>
                                    Round down if it is necessary
                                </p>
                                <p style={{ color: '#004D40', fontSize: '13px', marginTop: '-8px' }}>
                                    based on the asset you are buying
                                </p>
                            </Box>
                        :
                            null
                    }
                </form>
                </Grid>
            </div>
        </Container>
    );
}

export default RiskManagement;
