
import {Provider} from 'react-redux';
import {appStore} from './redux/store';
import {PersistGate} from 'redux-persist/integration/react';
import {persistStore} from 'redux-persist';

import Navigation from '../src/navigation';

const persistor = persistStore(appStore);

function App() {
    return (
        <Provider store={appStore}>
            <PersistGate loading={null} persistor={persistor}>
                <Navigation />
            </PersistGate>
        </Provider>
    );
}

export default App;
