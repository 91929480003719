import { css } from '@emotion/react'

const styles = {
    
    form: css`
        max-width: 400px;
        min-height: 200px;
        border: 1px solid #CFD8DC;
        border-radius: 5px;
        margin: 20px auto 80px auto;
        padding: 50px;
        box-shadow: 0px 0px 36px 15px rgba(207,216,220,0.75);
        -webkit-box-shadow: 0px 0px 36px 15px rgba(207,216,220,0.75);
        -moz-box-shadow: 0px 0px 36px 15px rgba(207,216,220,0.75);
    `,
    
    calText: css`
        margin: 60px auto 0 auto;
        text-align: center;

        & h1 {
            font-size: 18px;
            color: #00695C
        }
    `
}

export {styles};