// import {actionTypes} from './actionTypes';
import {REHYDRATE} from 'redux-persist/lib/constants';

const initialState = {
};

export default function core(state = initialState, action) {
    switch (action.type) {


        case REHYDRATE:
            if (!action.payload) return state;
            if (!action.payload.core) return state;

            return {
                ...state,
                ...action.payload.core,
            };

        default:
            return {...state};
    }
}
