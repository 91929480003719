/** @jsxImportSource @emotion/react */
import Container from '@material-ui/core/Container';
import {Link} from '@reach/router';

import {styles} from './styles';

function Home() {

    return (
        <>
            <div css={styles.background} />
            <div css={styles.main}>
                <Container>
                    <p>
                        Coming Soon...
                    </p>
                    <br />
                    <br />
                    <p>
                        <a href="https://www.youtube.com/channel/UC7QqMi_dhkGuFaA0TrYJTAw" target="_blank">
                        Youtube Channel Link
                        </a>
                    </p>
                    <Link to="risk-management">
                    Risk Management Calculator
                    </Link>
                </Container>
            </div>
        </>
    );
}

export default Home;
